import React from "react";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import Layout from "../components/Layout";
import Hero from "../components/modules/Hero";
import Seo from "../components/modules/Seo";
import {GatsbyImage} from  "gatsby-plugin-image";
import config from "../../data/SiteConfig";

const About = (props) => {
  return (
    <Layout>
      <div className="post about">
        <Helmet title={config.siteTitle} />
        <Hero fullWidth={props.data.firstImage.childImageSharp.gatsbyImageData} className="hero"/>
        <div className="flex center column basePad">
          <div className="article">
            <h1>Stein-Erik Olsen</h1>
            <p>Stein-Erik Olsen was educated at the Bergen Conservatory of Music and the Norwegian State Academy of Music. His post-graduate teachers included Alexandre Lagoya, at the Conservatoire National Supérieur de Musique de Paris, and the Czech pianist, Jiri Hlinka. His international career began in 1974, when he represented Norway at the Expo Norr Festival in Sweden.</p>
            <p>Since then he has played at many international festivals and toured extensively, playing solo recitals and concertos (including Rodrigo’s Concierto de Aranjuez), and appearing on radio and television throughout Europe, Great Britain, India and Korea. In 1986 his debut recital in the Wigmore Hall, London, merited high praise from the critics and his various recordings have been critically acclaimed world-wide.</p>
            <p>Stein-Erik Olsen has had a variety of works dedicated to him by eminent composers including Ketil Hvoslef, Olav Berg, John Duarte, Nikita Koshkin, Noel Zahler, Stepan Rak, etc., now featured on his recordings for Simax.</p>
            <p>Stein-Erik Olsen has performed at prestigious venues throughout Europe (Germany, France, Spain, Czech Republic, Hungary, Austria, Switzerland, Sweden, Denmark, etc.) and the Far East (including India, Nouvelle Calédonie and Korea).</p>
            <p>In Norway he has made many appearances at leading music festivals such as Bergen International Festival (where he premiered Olav Berg’s Guitar Concerto), Arve Tellefsens Chamber Music Festival, Oslo, and Stavanger International Chamber Music Festival.</p>
            <p>His engagements abroad include concerts at the Cesky Krumlov Festival, Czech Republic, Brussels International Guitar Festival, Belgium, Fanø Guitar Festival, Denmark, Estergom Guitar Festival, Hungary, etc. Stein-Erik Olsen has given recitals in leading halls in cities such as London, Prague, Lucerne, Bordeaux, Rome, Vienna, Paris, Stockholm, and Copenhagen.</p>
            <p>Stein-Erik Olsen has performed as soloist in a range of guitar concertos with the Philharmonic Orchestras of Bergen, Stavanger, Trondheim, and Frankfurt, and recorded with the Norwegian Chamber Orchestra and the Academy of St. Martin in the Fields. Eminent conductors he has appeared with include Jukka Pekka Saraste, Susanna Mälkki, Christian Eggen, Terje Mikkelsen, Asher Fisch, Michael Schønwandt, Lionel Friend, and Alexander Dmitriev</p>
            <p>Stein-Erik has a particular interest in performing chamber music and plays frequent concerts with Gro Schibsted Sandvik, professor at the Grieg Academy and principal flute in the Bergen and Oslo Philharmonic Orchestras. Stein-Erik has also given many recitals with the pan flute virtuoso Roar Engelberg, playing programmes which blend popular music, classical and folk music, Latino music and contemporary works. Over recent years he has gained an international reputation for his recitals and recordings in his guitar duo with Olivier Chassain, professor of guitar at the Conservatoire National Supérieur de Musique de Paris. Stein-Erik has also played duo recitals with the distinguished Swedish guitarist Göran Söllscher.</p>
            <p>Stein-Erik Olsen is professor of classical guitar at the Grieg Academy, University of Bergen.</p>
            <div className="flex column interviews">
              <h2>Interviews & Reviews</h2>
              <div className="flex interviewButtons wrap">
                <a href="/interviews/Interview_Classical_Guitar_Magazine_by_Graham_Wade.pdf" target="_blank">
                  <button className="button">
                    <p>Interview Classical Guitar Magazine by Graham Wade</p>
                  </button>
                </a>
                <a href="/interviews/Three_days_at_the_Abbey_Road_Studios.pdf" target="_blank">
                  <button className="button">
                    <p>Three days at the Abbey Road Studios</p>
                  </button>
                </a>
                <a href="/interviews/Interview_Paris_Recital_Stein-Erik_Olsen_and_Egil_Haugland.pdf" target="_blank">
                  <button className="button">
                    <p>Interview "Paris Recital" Stein-Erik Olsen and Egil Haugland</p>
                  </button>
                </a>
                <a href="/interviews/Ketil_Hvoslef_Interviewed_about_SEONVEH.pdf" target="_blank">
                  <button className="button">
                    <p>Ketil Hvoslef Interviewed about SEONVEH</p>
                  </button>
                </a>
                <a href="/interviews/Kritikk_PSC1313_klassekampen_35.pdf" target="_blank">
                  <button className="button">
                    <p>Review Klassekampen</p>
                  </button>
                </a>
                <a href="/interviews/Intervju_PSC1313_Dagbladet.pdf" target="_blank">
                  <button className="button">
                    <p>Interview Dagbladet</p>
                  </button>
                </a>
                <a href="https://musikkultur.no/musikkfolk/olsen-som-stakk-av-fra-flokken-6.337.629287.50f99b543b?fbclid=IwAR1MTreCyOOJqDw9Psg6VBoGCaU-YtSLg0wo96ayz969cT7pzOYsKI-UE24" target="_blank">
                  <button className="button">
                    <p>Interview Musikkultur</p>
                  </button>
                </a>
              </div>
            </div>
            <div className="flex column" style={{marginTop:'1rem'}}>
              <h2>Magazine frontpages</h2>
              <div className="flex column">
                <GatsbyImage image={props.data.fifthImage.childImageSharp.gatsbyImageData} style={{maxWidth: '392px'}} alt="Guitar International 1986 UK cover"/>
                <p style={{marginTop: 0}}>Guitar International 1986 UK</p>
                <GatsbyImage image={props.data.thirdImage.childImageSharp.gatsbyImageData} style={{maxWidth: '392px'}} alt="Classical Guitar Magazine 1987 UK cover"/>
                <p style={{marginTop: 0}}>Classical Guitar Magazine 1987 UK</p>
                <a href="/interviews/cgm_int_steinerikolsen2010.pdf">
                  <GatsbyImage image={props.data.fourthImage.childImageSharp.gatsbyImageData} style={{maxWidth: '392px'}} alt="Classical Guitar Magazine 2010 USA cover"/>
                  <p style={{marginTop: 0}}>Classical Guitar Magazine 2010 USA</p>
                </a>
              </div>
            </div>
            <div className="flex column" style={{marginTop:'1rem'}}>
              <h2 style={{marginBottom:'0'}}>Featured in Jeff Cogan's DVD about Daniel Friederich</h2>
              <h3 style={{marginTop:'0'}}>Interview and performance</h3>
              <div>
                <a href="https://franklin.library.upenn.edu/catalog/FRANKLIN_9968347733503681" target="_blank">
                  <GatsbyImage image={props.data.secondImage.childImageSharp.gatsbyImageData} alt={`Photo by:`} style={{maxWidth: '392px'}} alt="Jeff Cogan@s DVD avout Daniel Friederich cover" />
                </a>
                <p>Daniel Friederich has built outstanding guitars of distinction for more than 60 years. His instruments are played by hundreds of guitarists from around the world. They are some of the most coveted classical guitars ever made. This documentary film contains interviews with world famous performers speaking of their love of Friederich's work.... Friederich shows us his meticulous documentation, technical designs, measurements and specifications that he uses in his unique process.</p>
                <p>Narrated by Craig Brown; interviews by Jeff Cogan ; on-screen interviews: Daniel Friederich, Jack Sanders, Jonathan Kellerman, Scott Tennant, Pepe Romero, Celin Romero, Gaëlle Solal, David Chapman, Yuris Zeltins, David Tanenbaum, Roberto Aussel, Olivier Chassain, Bernard Maillot, Stein-Erik Olsen, Zoran Dukic.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default About;

export const aboutImageQuery = graphql`
  query {
    firstImage: file(relativePath: { eq: "img/SEO11.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    secondImage: file(relativePath: { eq: "img/dvdfriedrichcover.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 392)
      }
    }
    thirdImage: file(relativePath: { eq: "img/classicalguitarinternational1987.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 392)
      }
    }
    fourthImage: file(relativePath: { eq: "img/classicalguitarmagazine2010.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 392)
      }
    }
    fifthImage: file(relativePath: { eq: "img/classicalguitarmagazine1986cropped.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 392)
      }
    }
  }
`
